import { supabaseClient } from "@/lib/database/supabase";
import { Session, SignInWithOAuthCredentials, SignInWithPasswordlessCredentials, Subscription } from "@supabase/supabase-js";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export default defineStore("AuthStore", () => {
  const session = ref<Session | null>(null)
  const subscription = ref<Subscription>()

  const currentUser = computed(() => session.value?.user)
  const isSignedIn = computed(() => !!currentUser.value)
  
  async function subscribeToAuthChanges() {
    const { data } = await supabaseClient.auth.onAuthStateChange((_event, _session) => {
      session.value = _session
    })

    subscription.value = data.subscription
  }

  function unsubscribeFromAuthChanges() {
    subscription.value?.unsubscribe()
  }
  
  async function fetchSession() {
    const {data:{session: _session}} = await supabaseClient.auth.getSession()
    session.value = _session
  }

  async function signInWithEmail(email:string, options:SignInWithPasswordlessCredentials["options"] = {}) {
    return supabaseClient.auth.signInWithOtp({
      email,
      options,
    })
  }

  async function signInWithGoogle(options:Omit<SignInWithOAuthCredentials, "provider">["options"] = {}) {
    return supabaseClient.auth.signInWithOAuth({
      provider: 'google',
      options,
    })
  }

  async function signOut() {
    const {error} = await supabaseClient.auth.signOut()

    if (!error) {
      session.value = null
    }
  }

  return {
    session,
    currentUser,
    isSignedIn,
    
    subscribeToAuthChanges,
    unsubscribeFromAuthChanges,
    fetchSession,
    signInWithEmail,
    signInWithGoogle,
    signOut,
  }
})