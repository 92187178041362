import { Enums, Tables } from "@/lib/database/database.types"
import { TZDate } from "@date-fns/tz"
import { format } from "date-fns"

export default function (run:Tables<"runs">, timezone: Enums<"timezone">) {
  const startDate = new TZDate(run.start_at, timezone)
  const endDate = run.end_at ? new TZDate(run.end_at, timezone) : null

  if (!endDate) return format(startDate, "EEE, MMM do '@' h:mmaaa")
  
  return [
    format(startDate, "EEE, MMM do '@' h:mm"),
    format(endDate, "h:mmaaa"),
  ].join("-")
}