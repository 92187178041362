<template>
  <section class="ScheduleView">
    <section 
      v-for="season in seasonsWithRuns"
      :key="season.id"
      style="overflow-x: auto"
    >
      <table>
        <caption>{{ season.name }}</caption>
        <thead>
          <tr>
            <th>Players</th>
            <th
              v-for="run in season.runs"
              :key="run.id!"
            >
              {{ formatRunDate(run, run.court!.timezone) }}<br>
              <button 
                v-if="
                  currentUser && 
                  currentUser.id === run.user_id &&
                  run.start_at > now
                "
                :disabled="isAnnouncingRun"
                @click="announceRun(run)"
              >Announce</button>
              <button 
                v-if="
                  currentUser && 
                  currentUser.id === run.user_id &&
                  run.start_at > now
                "
                :disabled="isAnnouncingRun"
                @click="summarizeRun(run)"
              >Send summary</button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="player in sortedPlayers"
            :key="player.id"
          >
            <td>
              <component :is="currentUser && player.user_id === currentUser.id ? 'strong' : 'span'">{{ player.name }}</component>
              &nbsp;
              <button 
                v-if="!player.user_id && currentUser && player.email === currentUser.email"
                :disabled="isClaimingPlayer"
                @click="claimPlayer(player)"
              >Claim player</button>
            </td>
            <StatusCell
              v-for="run in season.runs"
              :key="run.id!"
              :modelValue="getProbability(run, player)"
              :isEditable="run.start_at! > now && !!currentUser && player.user_id === currentUser.id"
              @update:modelValue="updateStatus({run, player, probability: $event})"
            />
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td />
            <td
              v-for="run in season.runs"
              :key="run.id!"
            >
              <strong>
                <template v-if="run.inCount">{{ run.inCount }} in</template><template v-if="run.inCount && run.maybeCount">, </template>
                <template v-if="run.maybeCount">{{ run.maybeCount }} maybe</template>
                <template v-if="!run.inCount && !run.maybeCount">0 in</template>
              </strong>
            </td>
          </tr>
        </tfoot>
      </table>
    </section>
  </section>
</template>

<script lang="ts" setup>
import StatusCell from '@/components/StatusCell.vue';
import { Tables } from '@/lib/database/database.types';
import { supabaseClient } from '@/lib/database/supabase';
import formatRunDate from '@/lib/utils/date/formatRunDate';
import useAuthStore from '@/stores/useAuthStore';
import { QueryData, RealtimeChannel, RealtimePostgresDeletePayload, RealtimePostgresInsertPayload, RealtimePostgresUpdatePayload } from '@supabase/supabase-js';
import { storeToRefs } from 'pinia';
import { MessageSendingResponse } from 'postmark/dist/client/models';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

const {
  currentUser,
  session,
} = storeToRefs(useAuthStore())
const now = ref(new Date().toISOString()) // TODO: extract now to be realtime

const seasons = ref<Array<Tables<"seasons">>>([])
const runsWithCourtsQuery = supabaseClient.from("runs").select("*, court:courts(*)")
const runs = ref<QueryData<typeof runsWithCourtsQuery>>([])
const players = ref<Array<Tables<"players">>>([])
const statuses = ref<Array<Tables<"statuses">>>([])

const statusChannel = ref<RealtimeChannel | null>(null);
const statusMap = computed(():Map<string, Tables<"statuses">> => {
  return new Map(statuses.value.map((status) => [`${status.run_id}/${status.player_id}`, status]))
})

const isAnnouncingRun = ref(false)
const isClaimingPlayer = ref(false)

supabaseClient.from("seasons").select().then(({data: _seasons}) => {
  if (_seasons) {
    seasons.value = _seasons
  }
})

supabaseClient.from("players").select().then(({data: _players}) => {
  if (_players) {
    players.value = _players
  }
})

supabaseClient.from("statuses").select().then(({data: _statuses}) => {
  if (_statuses) {
    statuses.value = _statuses
  }
})

runsWithCourtsQuery
  .order("start_at", {ascending: false})
  .then(({data: _runs}) => {
    if (_runs) {
      runs.value = _runs.sort((a, b) => a.start_at.localeCompare(b.start_at))
    }
  })

onMounted(() => {
  statusChannel.value = supabaseClient
    .channel("statuses")
    .on("postgres_changes", { event: "INSERT", schema: "public", table: "statuses" }, handleStatusUpsert)
    .on("postgres_changes", { event: "UPDATE", schema: "public", table: "statuses" }, handleStatusUpsert)
    .on("postgres_changes", { event: "DELETE", schema: "public", table: "statuses" }, handleStatusDelete)
    .subscribe();
})

onBeforeUnmount(() => {
  statusChannel.value?.unsubscribe();
  statusChannel.value = null;
})

const seasonsWithRuns = computed(() => {
  return seasons.value.map((season) => {
    return {
      ...season,
      runs: runsWithCounts.value.filter((run) => run.season_id === season.id)
    }
  })
})

const runsWithCounts = computed(() => {
  return runs.value.map((run) => {
    return {
      ...run,
      ...statuses.value.filter((status) => status.run_id === run.id).reduce((acc, status) => {
        if (status.probability === 1) {
          acc.inCount++
        } else if (status.probability === 0) {
          acc.outCount++
        } else if (status.probability === 0.5) {
          acc.maybeCount++
        }

        return acc
      }, {
        inCount: 0,
        outCount: 0,
        maybeCount: 0,
      })
    }
  })
})

const sortedPlayers = computed(() => {
  return [...players.value].sort((a, b) => {
    if (currentUser.value && a.user_id === currentUser.value.id) return -1
    if (currentUser.value && b.user_id === currentUser.value.id) return 1

    return a.name.localeCompare(b.name)
  })
})

function getProbability(run:Tables<"runs">, player:Tables<"players">) {
  return statusMap.value.get(`${run.id}/${player.id}`)?.probability ?? null
}

async function announceRun(run:Tables<"runs">) {
  isAnnouncingRun.value = true
  
  const response = await fetch(`/api/runs/${run.id}/announce`, {
    method: "POST",
    headers: {
      authorization: session.value!.access_token
    }
  })
  const {sentEmails, error}:{sentEmails?: Array<MessageSendingResponse>, error?: Error} = await response.json()
  if (sentEmails) {
    alert(sentEmails.length > 0 ? `Sent ${sentEmails.length} emails` : `No emails sent. All players have already been contacted`)
  } else if (error) {
    alert(`Error: ${error.message}`)
  }
  
  isAnnouncingRun.value = false
}

async function summarizeRun(run:Tables<"runs">) {
  isAnnouncingRun.value = true
  
  const response = await fetch(`/api/runs/${run.id}/summarize`, {
    method: "POST",
    headers: {
      authorization: session.value!.access_token
    }
  })
  const {sentEmails, error}:{sentEmails?: Array<MessageSendingResponse>, error?: Error} = await response.json()
  if (sentEmails) {
    alert(sentEmails.length > 0 ? `Sent ${sentEmails.length} emails` : `No emails sent. Did you forget to announce the run?`)
  } else if (error) {
    alert(`Error: ${error.message}`)
  }
  
  isAnnouncingRun.value = false
}

function addOrReplaceStatus(status:Tables<"statuses">) {
  const existingStatus = statuses.value.find((_status) => _status.run_id === status.run_id && _status.player_id == status.player_id)

  if (existingStatus) {
    removeStatus(status)
  }

  statuses.value.push(status)
}

function removeStatus(status:Partial<Tables<"statuses">>) {
  const index = statuses.value.findIndex((_status) => _status.run_id === status.run_id && _status.player_id === status.player_id)
  
  if (index !== -1) {
    statuses.value.splice(index, 1)
  }
}

async function updateStatus({run, player, probability}:{run:Tables<"runs">, player:Tables<"players">, probability:number | null}) {
  if (probability === null) return;

  const {data: status, error} = await supabaseClient.from("statuses").upsert({
    run_id: run.id!,
    player_id: player.id,
    probability,
  }).select().limit(1).maybeSingle();

  if (status && !error) {
    addOrReplaceStatus(status)
  } else if (error) {
    alert(error.message)
  }
}

async function claimPlayer(player:Tables<"players">) {
  if (!currentUser.value) return

  isClaimingPlayer.value = true
  const {data: _player, error} = await supabaseClient
    .from("players")
    .update({
      user_id: currentUser.value.id,
    })
    .eq("id", player.id)
    .select()
    .maybeSingle()
  isClaimingPlayer.value = false

  if (_player) {
    player.user_id = _player.user_id
  } else if (error) {
    alert(error.message)
  }
}

function handleStatusUpsert (payload:RealtimePostgresInsertPayload<Tables<"statuses">> | RealtimePostgresUpdatePayload<Tables<"statuses">>) {
  addOrReplaceStatus(payload.new)
}

function handleStatusDelete (payload:RealtimePostgresDeletePayload<Tables<"statuses">>) {
  removeStatus(payload.old)
}
</script>

<style lang="scss" scoped>
.ScheduleView {
  display: grid;
  gap: 16px;
}

table {
  border-collapse: collapse;
  white-space: nowrap;
}

table > caption {
  padding: 8px;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid lightgrey;
  border-bottom: none;
  text-align: left;

  @media (prefers-color-scheme: dark) {
    border-color: rgba(255, 255, 255, 0.25);
  }
}

th,
td {
  padding: 4px 8px;
  border: 1px solid lightgrey;
  text-align: center;

  &:first-child {
    text-align: left;
  }

  @media (prefers-color-scheme: dark) {
    border: 1px solid rgba(255, 255, 255, 0.25);
  }
}
</style>
