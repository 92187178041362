<template>
  <AuthView />
  <template v-if="currentUser?.email === 'jonnie.hallman@gmail.com'">
    <br>
    <CommissionerView />
  </template>
  <br>
  <ScheduleView />
</template>

<script lang="ts" setup>
import useAuthStore from '@/stores/useAuthStore';
import AuthView from '@/views/AuthView.vue';
import CommissionerView from '@/views/CommissionerView.vue';
import ScheduleView from '@/views/ScheduleView.vue';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute()
const {currentUser} = storeToRefs(useAuthStore())

onMounted(() => {
  if (route.query.error && route.query.error_description) {
    alert(route.query.error_description)
  }
})
</script>

<style lang="scss" scoped>
</style>