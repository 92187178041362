<template>
  <section>
    <progress v-if="isRequesting" />
    <form 
      v-else
      @submit="handleInviteUser"
    >
      <fieldset :disabled="isRequesting">
        <h2>Invite a player</h2>
        <label>
          Name
          <input 
            type="name"
            v-model="form.name"
            required
          >
        </label>
        <label>
          Email
          <input 
            type="email"
            v-model="form.email"
            required
          >
        </label>
        <button
          type="submit"
        >Send</button>
      </fieldset>
    </form>
  </section>
</template>

<script lang="ts" setup>
import useAuthStore from '@/stores/useAuthStore';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

const {session} = storeToRefs(useAuthStore())

const form = ref(getInitialForm())
const isRequesting = ref(false)

function getInitialForm() {
  return {
    name: "",
    email: "",
  }
}

async function handleInviteUser() {
  isRequesting.value = true
  try {
    const response = await fetch("/api/users/invite", {
      method: "POST",
      body: JSON.stringify(form.value),
      headers: {
        authorization: session.value!.access_token
      }
    })

    if (response.ok) {
      form.value = getInitialForm()
    } else {
      const {error} = await response.json()
      alert(error)
    }
  } catch (err) {
    alert(JSON.stringify(err))
  }
  isRequesting.value = false
}
</script>

<style lang="scss" scoped>

</style>